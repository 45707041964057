var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"getTicketMethods"},[(_vm.mark)?_c('MySwiper',{ref:"my-swiper",staticClass:"container-wrapper",attrs:{"swiper-type":_vm.type,"swiper-list":_vm.ticketItemInfos,"slides-per-view":1,"space-between":30,"initial-slide":_vm.activeIndex,"show-arrow":true},on:{"slideChange":_vm.slideChanges},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [(
          _vm.ticketInfos.productSourceEnum == 'POLY' &&
          _vm.ticketInfos.isSellOut == '2'
        )?_c('van-row',{staticClass:"preSalebox"},[_c('p',{staticClass:"preSaleStatusName"},[_vm._v("配票中")]),_c('p',{staticClass:"preSaleTips"},[_vm._v(" 预售阶段暂不展示二维码，"),_c('br'),_vm._v("演出开售后支持自助取票和快递取票 ")]),_c('p'),_c('van-image',{staticClass:"preSale",attrs:{"fit":_vm.imageFit,"width":"3rem","height":"3rem","src":require(`@/assets/images/Tickets/preSale.png`)}})],1):_c('van-row',{staticClass:"QRcode-area"},[(item.isTicketVoided)?_c('van-image',{staticClass:"disabledTicstatus",attrs:{"fit":_vm.imageFit,"width":"1.9rem","height":"1.5rem","src":require('@/assets/images/Wallet/disabledTick.png')}}):_vm._e(),(
            _vm.productSourceEnum == 'MY' &&
            item.seatMatchStatus &&
            item.seatMatchStatus !== 3
          )?_c('div',{staticClass:"preSalebox"},[_c('p',{staticClass:"preSaleTips"},[_vm._v("开演前公开")]),_c('p'),_c('van-image',{staticClass:"preSale",attrs:{"fit":_vm.imageFit,"width":"3rem","height":"3rem","src":require(`@/assets/images/Tickets/preSale.png`)}})],1):_vm._e(),(item.deliveryWay === '03')?_c('div',{staticClass:"elec-way",class:{
            disabledTick:
              item.isTicketVoided ||
              _vm.currentQrcodeStatus === '1' ||
              _vm.currentQrcodeStatus === '2',
          }},[_c('div',{ref:`qrCodeUrl${item.orderDetailId}`,staticClass:"qrcode"}),(_vm.currentQrcodeStatus === '1')?_c('van-image',{staticClass:"invate-img",attrs:{"fit":_vm.imageFit,"src":_vm.getQrCodeArea(
                item.deliveryWay,
                item.printStatus,
                item.validateStatus,
                item.invalidStatus
              ).url2}}):_vm._e()],1):_vm._e(),(_vm.productSourceEnum === 'MH' && item.deliveryWay === '01')?_c('div',{class:item.isTicketVoided ? 'other-way disabledTick' : 'other-way',staticStyle:{"width":"100%","text-align":"center"}},[_c('van-image',{staticClass:"elec-way",attrs:{"fit":_vm.imageFit,"id":"mhimg","src":`data:image/png;base64,${item.imageCode}`}})],1):_c('div',[(
              (_vm.productSourceEnum === 'POLY' &&
                item.deliveryWay === '01' &&
                !item.printStatus) ||
              ((_vm.productSourceEnum === 'MY' || _vm.productSourceEnum === 'DM') &&
                (item.deliveryWay === '04' ||
                  (item.deliveryWay === '05' && !item.printStatus) ||
                  item.deliveryWay === '01'))
            )?_c('vue-qrcode',{class:item.isTicketVoided ? 'other-way disabledTick' : 'other-way',attrs:{"value":item.ticketCode ? item.ticketCode : '',"size":150}}):_c('van-image',{staticClass:"pick-image",attrs:{"fit":_vm.imageFit,"src":_vm.getQrCodeArea(
                item.deliveryWay,
                item.printStatus,
                item.validateStatus,
                item.invalidStatus
              ).url1}})],1)],1)]}}],null,false,4102424450)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }