
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Cell, CellGroup, Image, Field, Row } from "vant";
import StatusBar from "@/components/StatusBar.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Image.name]: Image,
    [Field.name]: Field,
    [Row.name]: Row,
    StatusBar,
  },
})
export default class Wallet extends Mixins(Mixin) {
  @Prop({
    type: Boolean,
    default: false,
  })
  public isEnd!: boolean;
  @Prop({ default: [], type: Array })
  public tickets!: Array<order.TicketFolderItem>;
  goDetails(item: order.TicketFolderItem): void {
    this.$emit("goWalletDetail", item);
  }
}
