
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import Wallet from "@/views/Mine/MoreServices/Wallet/Wallet.vue";

@Component({
  components: {
    Wallet,
  },
})
export default class ExternalWalletPage extends Mixins(Mixin) {}
