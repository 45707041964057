
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins } from "vue-property-decorator";
import { Row, Image } from "vant";
@Component({
  components: { [Row.name]: Row, [Image.name]: Image },
})
export default class DetailHead extends Mixins(Mixin) {
  public ticketInfos: order.TicketFolderDetailDto = {};
  getInfo(params: order.TicketFolderDetailDto): void {
    this.ticketInfos = params;
  }
}
